import { createStore } from "vuex";
import { auth, provider, usersCollection } from "../utils/firebaseConfig";
import router from "../router/index";

export default createStore({
  state: {
    userProfile: {},
  },
  mutations: {
    setUserProfile(state, value) {
      state.userProfile = value;
    },
  },
  actions: {
    async login({ dispatch }) {
      // sign user in
      const user = await auth.signInWithPopup(provider);
      // fetch user profile and set in state
      dispatch("fetchUserProfile", user);
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await usersCollection.doc(user.uid).get();
      // set user profile in state
      commit("setUserProfile", userProfile.data());

      // change route to dashboard
      router.push("/contests");
    },
    async logout({ commit }) {
      await auth.signOut();

      // clear userProfile and redirect to /login
      commit("setUserProfile", {});
      router.push("/");
    },
  },
  modules: {},
});
