<template>
  <div id="nav">
    <div class="navLogo">
      <img src="./assets/logo.png" />
      <h3>FCP App Admin</h3>
    </div>
    <div class="navMenu">
      <router-link to="/">Home</router-link> |
      <router-link to="/contests">Contests</router-link>
      <div>
        <button class="btn btn-danger" @click="onLogout">Logout</button>
      </div>
    </div>
  </div>
  <div class="pageBody">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
<style lang="scss">
/* Colors */
$primary-white: rgb(242, 242, 242);
$primary-green: rgb(100, 223, 93);
$primary-blue: #2a56e4;
$primary-gray: #2c3e50;

/* Styles */
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-gray;

  .pageBody {
    padding: 15px;
  }
}

#nav {
  align-items: center;
  background-color: $primary-blue;
  color: $primary-white;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 30px;

  .navLogo {
    align-items: center;
    justify-content: flex-start;
    color: $primary-gray;
    display: flex;
    font-weight: bold;
    text-align: left;

    img {
      width: 50px;
      margin-right: 15px;
    }

    h3 {
      color: $primary-white;
      margin: 0;
      min-width: max-content;
    }
  }

  .navMenu {
    display: flex;
    justify-content: space-around;
    align-items: center;

    a {
      font-weight: bold;
      color: $primary-white;
      padding: 0 5px;

      &.router-link-exact-active {
        color: $primary-green;
      }
    }
    .btn {
      margin: 0 5px;
    }
  }
}
</style>
