import { createApp } from "vue";
import { auth } from "./utils/firebaseConfig";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";

let app;
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = createApp(App);
    app.use(store);
    app.use(router);
    app.mount("#app");
  }

  if (user) {
    store.dispatch("fetchUserProfile", user);
  }
});
