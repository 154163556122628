<template>
  <div class="home">
    <input
      type="button"
      class="btn btn-primary"
      @click="onLogin"
      value="Google Sign In"
    />
  </div>
  <div>
    <modal :show="show" :closeModal="closeModal" />
  </div>
</template>

<script>
// import { login } from "../../api";
import Modal from "../../components/Modal.vue";

export default {
  name: "Home",
  components: {
    modal: Modal,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    /* onLogin() {
      login().then(() => this.$router.push("/contests"));
    }, */
    onLogin() {
      this.$store.dispatch("login").then(() => this.$router.push("/contests"));
    },
  },
};
</script>
