import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/Index.vue";
import { auth } from "../utils/firebaseConfig";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contests",
    name: "Contests",
    component: () => import("../views/Contests/Index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    alert(
      "Debes estar logueado para ver esta página. Por favor, inicia sesión!"
    );
    next("/");
  } else {
    next();
  }
});

export default router;
